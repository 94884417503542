.home__container {
  padding-top: calc(1.5em + 40px);
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 1.5em - 40px);
  background-color: var(--secondary-color);
}

.home__image {
  width: 100%;
  object-fit: cover;
  height: 40vh;
}
