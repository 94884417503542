.market-item-header__container {
  padding-top: calc(1.5em + 40px + 11%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 1.5em - 40px - 12%);
  padding-left: 20px;
  background-color: var(--primary-color);
}

.market-item-header__top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.market-item-header__text,
.market-item-header__bio {
  color: var(--secondary-color);
  font-family: "Mercantic", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
}

.market-item-header__name,
.market-item-header__date {
  color: var(--secondary-color);
  font-family: "Mercantic", sans-serif;
  font-size: 60px;
  font-style: normal;
}

.market-item-header__time,
.market-item-header__price {
  color: var(--secondary-color);
  font-family: "Mercantic", sans-serif;
  font-size: 30px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
}

.market-item-header__bottom {
  display: flex;
  flex-direction: column;
}

.market-item-header__bottom-first {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .market-item-header__container {
    height: auto;
    padding-top: 15px;
    padding-right: 20px;
    width: calc(100% - 40px);
  }
  .market-item-header__text,
  .market-item-header__bio {
    font-size: 12px;
  }
  .market-item-header__time,
  .market-item-header__price {
    font-size: 24px;
  }
  .market-item-header__name,
  .market-item-header__date {
    font-size: 40px;
  }
  .market-item-header__bottom-first {
    margin-top: 80px;
  }
}
