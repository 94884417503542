.shopping-cart__container {
  width: 100%;
  margin-top: calc(1.5em + 40px);
  background: #c9dbdc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 1.5em - 40px);
}

.shopping-cart__list {
  height: 30%;
  min-height: 30%;
}

.shopping-cart__valid-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  background-color: #c9dbdc;
}
.shopping-cart__valid-container-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}
.shopping-cart__valid-container-wrapper span {
  color: #bf1646;
  font-family: "Mercantic";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.2px;
}
@media screen and (max-width: 768px) {
  .shopping-cart__container > .market-row-cart__container:last-child::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    height: 1px;
    background-color: var(--primary-color);
  }
}
