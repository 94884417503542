.month-title__container {
  width: calc(100% - 20px);
  padding-left: 20px;
  background-color: var(--primary-color);
  padding-top: 10px;
  padding-bottom: 10px;
}

.month-title__container span {
  font-size: 1.1em;
  color: var(--secondary-color);
  font-family: "mercantic", sans-serif;
  letter-spacing: 1.2px;
}
