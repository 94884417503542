.navbar__container {
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 999;
}

.navbar__container::after {
  content: "";
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
  height: 1px;
  background-color: var(--primary-color);
}

.navbar__logo:hover {
  cursor: pointer;
}
.navbar__container span {
  font-family: "mercantic", sans-serif;
  font-size: 1.5em;
  line-height: 1;
}

.navbar__icon {
  font-size: 1.5em; /* Mida basada en la mida del text */
  vertical-align: middle; /* Alinea la icona verticalment amb el text */
}

.navbar__icon:hover {
  cursor: pointer;
}
