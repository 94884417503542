.empty-cart__container {
  height: calc(100vh - 0.5em - 50px);
  width: 100vw;
  background-color: var(--primary-color);
  padding-top: 50px;
  margin-top: 0.5em;
}

.empty-cart__text {
  color: var(--secondary-color);
  font-family: "Mercantic", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 66px */
  letter-spacing: -0.6px;
  margin: 0;
  padding-left: 20px;
}
