.market-item__container {
  width: 100%;
  height: 100%;
  display: flex;
}

.market-item__image {
  width: 50%;
  height: 100vh;
  object-fit: cover;
  position: fixed;
}

.market-item__info-container {
  width: 50%;
  height: 100vh;
  background-color: var(--primary-color);
  position: absolute;
  right: 0;
}

.market-item__additions-container {
  background-color: var(--primary-color);
  padding: 20px;
}

.market-item__additions-text {
  font-size: 16px;
  font-family: "mercantic", sans-serif;
  color: var(--tertiary-color);
  margin-bottom: 1em;
  display: block;
}
.market-item__additions-row {
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid var(--tertiary-color);
}
.market-item__additions-row:last-child {
  border-bottom: 1px solid var(--tertiary-color);
}

.market-item__additions-row__left {
  width: 30%;
  display: flex;
  justify-content: space-between;
}
.market-item__additions-row__right {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.market-item__additions-row span {
  font-family: "mercantic", sans-serif;
  font-size: 16px;
  color: var(--tertiary-color);
}
.market-item__additions-row__right span {
  height: fit-content;
  align-self: center;
}
.market-item__additions-row__sign:hover {
  cursor: pointer;
}
.market-item__map-container {
  width: calc(100% - 40px);
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
  padding-top: 50px;
}
.market-item__map__title {
  font-size: 16px;
  font-family: "mercantic", sans-serif;
  color: var(--tertiary-color);
  margin-bottom: 15px;
}
.market-item__map__bottom-text {
  font-size: 12px;
  font-family: "mercantic", sans-serif;
  color: var(--tertiary-color);
  margin-top: 10px;
}
.market-item__add-to-cart__container {
  padding-top: 50px;
  background-color: var(--primary-color);
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 40px);
}
.market-item__add-to-cart__top span {
  font-family: "mercantic", sans-serif;
  font-size: 24px;
  color: var(--tertiary-color);
  padding-top: 8px;
  padding-bottom: 8px;
}
.market-item__add-to-cart__top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.market-item__add-to-cart__top span:first-child:hover {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .market-item__container {
    flex-direction: column;
  }
  .market-item__image {
    width: 100%;
    height: 60vh;
  }
  .market-item__info-container {
    width: 100%;
  }
  .market-item__additions-row__left {
    width: 50%;
  }

  .market-item__additions-row__left span {
    font-size: 14px;
  }
}
