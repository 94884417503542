@import "normalize.css";

:root {
  --primary-color: hsl(343, 79%, 42%);
  --secondary-color: #d9d9d9;
  --tertiary-color: #c9dbdc;
}

@font-face {
  font-family: "Mercantic";
  src: url("./assets/Typo/FFFMercantic-Regular.woff2") format("woff2"),
    url("./assets//Typo/FFFMercantic-Regular.woff") format("woff");
}

@font-face {
  font-family: "Affairs";
  src: url("./assets/Typo/Affairs-Regular.otf");
}

body {
  margin: 0;
}
