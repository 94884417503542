.market-row__container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-color);
  background-color: var(--secondary-color);
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 40px);
}
.market-row__container-special {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-color);
  background-color: var(--secondary-color);
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 40px);
}
.market-row-cart__container {
  display: flex;
  justify-content: space-between;
  background: #c9dbdc;
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 40px);
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}
.market-row-cart__container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  height: 1px;
  background-color: var(--primary-color);
}

.market-row-cart__container:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.market-row__left {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.market-row__right {
  display: flex;
  justify-content: flex-end;
  gap: 12%;
  width: 50%;
}

.market-row__container,
.market-row-cart__container,
.market-row__container-special span {
  font-family: "mercantic", sans-serif;
  font-size: 1.1em;
  letter-spacing: 1.2px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.market-row__container:hover {
  background-color: var(--primary-color);
  color: var(--primary-color) !important;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-color);
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 40px);
}
.market-row__container-special:hover {
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-color);
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 40px);
}
.market-row__left-cart {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.market-row__right-cart {
  display: flex;
  justify-content: flex-end;
  gap: 12%;
  width: 50%;
}
.cart__delete:hover {
  cursor: pointer;
}
.home__add:hover {
  cursor: pointer;
}
.cart__redirect:hover {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .market-row__left {
    flex-direction: column;
  }
  .market-row__left-cart {
    flex-direction: column;
  }
  .market-row__container-special {
    color: var(--primary-color) !important;
  }
}
