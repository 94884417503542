.success__container {
  height: calc(100vh - 50px);
  width: 100vw;
  background-color: var(--secondary-color);
  padding-top: 50px;
}

.success__text {
  color: var(--primary-color);
  font-family: "Mercantic", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 66px */
  letter-spacing: -0.6px;
  padding-left: 20px;
  max-width: 70%;
  margin-top: 0.5em;
}
