.form__container {
  width: calc(100% - 40px);
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  gap: 15px;
  background: #c9dbdc;
  margin-top: 2em;
  padding-bottom: 1em;
}

.form__items-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form__payment-button {
  width: calc(100% - 40px);
  border-radius: 30px;
  border: 1px solid var(--primary-color);
  color: #c9dbdc;
  font-family: "Mercantic", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.2px;
  padding: 10px 20px; /* Afegeix padding aquí */
  background: var(--primary-color);
}

.form__payment-button:hover {
  cursor: pointer;
}

.error-sign {
  position: absolute;
  right: 10px; /* Posició de la icona dins l'input */
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
}

.custom-file-upload {
  width: calc(100% - 40px);
  border-radius: 30px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-family: "Mercantic", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.2px;
  padding: 10px 20px; /* Afegeix padding aquí */
  background: #c9dbdc;
  outline: "none";
  cursor: pointer;
}
.label-placeholder {
  color: var(--primary-color);
  opacity: 0.65;
}
.label-text {
  color: var(--primary-color);
  opacity: 1 !important;
}
.input:focus {
  border: 1px solid var(--primary-color);
  outline: none; /* Evita el contorn per defecte */
}

@media screen and (max-width: 768px) {
  .form__container {
    display: flex;
    flex-direction: column;
  }
  .form__items-container {
    width: 100%;
  }
}
