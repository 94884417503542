.input {
  width: calc(100% - 40px);
  border-radius: 30px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-family: "Mercantic", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.2px;
  padding: 10px 20px; /* Afegeix padding aquí */
  background: #c9dbdc;
  outline: "none";
}

.input:focus {
  border: 1px solid var(--primary-color);
  outline: none; /* Evita el contorn per defecte */
}

.error {
  background: rgba(191, 22, 70, 0.2);
}
